.wrap {
  width: 100%;
  background-color: #161616;
}

// inner {
.inner {
  width: 128rem;
  margin: 0 auto;
}

.header {
  padding: 5.4rem 0 6.1rem;

  .header__logo {
    width: 18.3rem;
  }
}

.topVisual {
  height: 22.3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;

  &.is-type-1 {
    background-image: url("../images/top-visual-1.png");
  }
}

.contents {
  padding: 5rem 0 18.5rem;
}

.footer {
  padding: 5rem 0;
  border-top: 0.1rem solid #6a6a6a;

  .footer__caution {
    margin-top: 2.4rem;
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.5);
  }

  .companyInfo {
    @include display-flex($display: flex, $alignItems: center, $gap: 2.4rem);
    font-size: 1.4rem;
    font-weight: 500;
    color: #c4c4c4;

    strong {
      margin-right: 0.6rem;
      font-weight: 700;
    }
  }

  .copyright {
    margin-top: 0.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #6a6a6a;
  }
}

// 뷰포트의 가로너비가 744px 이상 1024px 이하 : 태블릿 환경
@media screen and (min-width: 744px) and (max-width: 1279px) {
  .inner {
    padding: 0 5rem;
    width: 100%;
  }

  .header {
    padding: 3.8rem 0 3rem;

    .inner {
      padding: 0 6.7rem;
    }
  }

  .topVisual {
    height: 10.5rem;
  }

  .contents {
    padding: 4.6rem 0 13.1rem;
  }

  .footer {
    padding: 3.4rem 0 2.5rem;

    .inner {
      padding: 0 2.8rem;
    }
  }
}

// 뷰포트의 가로너비가 743px 이하인 경우 적용 : 모바일 환경
@media screen and (max-width: 743px) {
  .inner {
    padding: 0 2.2rem;
    width: 100%;
  }

  .header {
    padding: 3.3rem 0 2.4rem;

    .header__logo {
      width: 10rem;
    }
  }

  .topVisual {
    height: 6.5rem;
  }

  .contents {
    padding: 2.7rem 0 9.2rem;
  }

  .footer {
    padding: 3.3rem 0 3.2rem;

    .inner {
      padding: 0 0.9rem;
    }

    .companyInfo {
      flex-wrap: wrap;
      gap: 1.2rem 2.4rem;
    }
  }
}
