.header {
  position: relative;

  .inner {
    position: relative;
  }

  .header__breadcrumbs {
    @include display-flex($display: flex, $alignItems: center, $gap: 3.6rem);
    font-weight: 500;
  }

  .header__user {
    @include display-flex($display: flex, $alignItems: center, $gap: 0.6rem);
    padding: 1rem 1.2rem;
    border-radius: 999rem;
    background-color: #2d2d2d;

    .header__userImg {
      flex-shrink: 0;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      overflow: hidden;
    }

    .user__name {
      font-size: 1.6rem;
      letter-spacing: 0.09rem;
    }
  }
}

.contents__box {
  padding: 2.6rem 3.6rem;
  border-radius: 1rem;
  background-color: #262626;

  .contents__boxTit {
    font-size: 2rem;
  }

  .contents__boxTxt {
    margin-top: 1rem;
  }
}

.guide {
  .guide__btn {
    &.is-active {
      + .guide__con {
        display: block;
      }
    }
  }
  .guide__con {
    display: none;
    position: absolute;
    top: 6.7rem;
    left: 50%;
    padding: 4rem 5rem 3.1rem;
    width: 113rem;
    border-radius: 2.1rem;
    background-color: #fff;
    color: #262626;
    transform: translateX(-50%);
    z-index: 100;

    &::before {
      content: "";
      position: absolute;
      right: 15.2rem;
      top: 4rem;
    }

    .guide__img {
      @include display-flex(
        $display: flex,
        $flexDirection: column,
        $alignItems: center,
        $justifyContent: center
      );
      margin: 0 auto;
      height: 11.4rem;

      img {
        display: block;
      }

      &.is-img-1,
      &.is-img-4 {
        width: 22.7rem;
      }

      &.is-img-2 {
        width: 12.6rem;
      }

      &.is-img-3 {
        width: 17.6rem;
      }
    }

    .guide__list {
      @include display-flex($display: flex, $gap: 0.8rem);
      --cols: 4;
      --gap: 0.8rem;

      .guide__item {
        @include display-flex(
          $display: flex,
          $flexDirection: column,
          $alignItems: center,
          $justifyContent: space-between
        );
        padding: 5rem 0 3rem;
        position: relative;
        flex-basis: calc(
          100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1)
        );
        height: 25.6rem;
        text-align: center;
        border-radius: 0.8rem;
        border: 0.1rem solid #f1f1f1;

        .guide__tit {
          display: inline-block;
          position: absolute;
          top: -0.9rem;
          padding-left: 6.8rem;
          padding-right: 1.6rem;
          border-radius: 999rem;
          border: 0.1rem solid #e3e3e3;
          font-size: 1.8rem;
          background-color: #fff;

          .num {
            position: absolute;
            padding: 0.4rem 1.5rem;
            left: -0.3rem;
            top: 50%;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 2.9rem;
            border-radius: 999rem;
            color: #fff;
            background-color: #ff852b;
            transform: translateY(-50%);
          }

          .tit {
            padding: 0.5rem;
            line-height: 2.5rem;
            color: #ff852b;
          }
        }

        .guide__text {
          font-size: 1.3rem;
          font-weight: 500;
        }
      }
    }
  }
}

.gameState {
  @include display-flex($display: flex, $alignItems: center, $gap: 2.8rem);

  .tag {
    padding: 1.5rem 3rem 1.4rem;
    font-size: 2.6rem;
    font-weight: 600;
    color: #f8f8f8;
  }

  .gameState__icon {
    width: 4.48rem;
    height: 4.48rem;
    border-radius: 50%;
    background-color: #fff;
    background: #fff url("../images/icon-star.svg") no-repeat center / 3.2rem
      3.2rem;
  }

  .gameState__progress {
    flex-grow: 1;

    .gameState__progressVal {
      margin-left: 0.5rem;
      font-size: 3rem;
      font-weight: 500;
      color: #e3e3e3;
    }

    .progress {
      margin-top: 1.3rem;
    }
  }
}

.rewardList {
  @include display-flex($display: flex, $alignItems: center, $gap: 1rem);
  margin-top: 3.6rem;
  font-size: 1.548rem;

  &::-webkit-scrollbar {
    height: 0.5rem;
    border-radius: 999rem;
    background-color: transparent;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 990rem;
    background-color: #9d9d9d;
  }

  &::-webkit-scrollbar-track {
    border-radius: 999rem;
    background-color: #3f3f3f;
  }

  .rewardList__item {
    padding: 1.2rem 1rem;
    width: 10%;
    text-align: center;
    border-radius: 1.3rem;
    background-color: #262626;

    span {
      display: block;
      color: #484848;
    }

    &.is-active,
    &.is-current {
      span {
        color: #fff;
      }

      button {
        background-color: #000;
      }
    }

    &.is-current {
      background-color: #ff852b;
    }
  }

  .rewardList__img {
    margin-top: 0.5rem;
    border-radius: 1.3rem;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .rewardList__btn {
    margin-top: 1.3rem;

    button {
      height: 3.1rem;
      border-radius: 1.3rem;
      color: #fff;
      background-color: #c0c0c0;
    }
  }

  + .contents__box {
    margin-top: 3.5rem;
  }
}

.gameList {
  margin-top: 8.6rem;

  .gameList__top {
    @include display-flex(
      $display: flex,
      $alignItems: center,
      $justifyContent: space-between
    );

    .gameList__search {
      @include display-flex($display: flex, $alignItems: center, $gap: 2.3rem);
    }

    .searchForm {
      position: relative;

      .searchForm__input {
        padding: 1.2rem 8.5rem 1.3rem 4.3rem;
        border-radius: 999rem;
        border: none;
        outline: none;
        font-size: 2rem;
        line-height: 2.9rem;
        background-color: #2d2d2d;
        color: #fff;

        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      .searchForm__btn {
        position: absolute;
        top: 50%;
        right: 2.3rem;
        width: 3.5rem;
        height: 3.5rem;
        transform: translateY(-50%);
        background: url("../images/icon-search.svg") no-repeat center;
      }
    }
  }

  .gameList__count {
    font-size: 1.6rem;
    flex-shrink: 0;
  }

  .gameList__tab {
    .tab {
      position: relative;

      + .tab {
        padding-left: 2.2rem;
        margin-left: 2.1rem;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 0.1rem;
          height: 1.6rem;
          background-color: #fff;
          transform: translateY(-50%);
        }
      }
    }

    .tab__link {
      font-size: 1.4rem;
      color: #545454;

      &.is-active {
        color: #fff;
      }
    }
  }

  .gameList__list {
    @include display-flex($display: flex, $flexWrap: wrap, $gap: 2.2rem 1.8rem);
    --cols: 4;
    --gap: 1.8rem;
    margin-top: 4.7rem;

    .gameList__listItem {
      position: relative;
      flex-basis: calc(
        100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1)
      );
    }

    .gameList__listImg {
      position: relative;
      height: 19rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      overflow: hidden;
    }

    .tag {
      position: absolute;
      top: 2rem;
      left: 2rem;
      padding: 0.5rem 1rem;
      gap: 0.8rem;
      z-index: 1;
    }

    .gameList__listCon {
      padding: 0.8rem 2rem 2rem;
      background-color: #262626;

      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 1.2rem;
        color: #a7a7a7;
      }

      .btnWrap {
        margin-top: 1.5rem;
      }

      .btn {
        padding: 1.4rem 0 1.3rem;
        font-size: 1.4rem;

        + .btn {
          margin-top: 1rem;
        }
      }
    }
  }
}

.survey {
  margin: 0 auto;
  padding-top: 4rem;
  width: 64rem;

  &.is-finish {
    padding-top: 10.3rem;
  }

  .survey__top {
    text-align: center;
  }

  .survey__company {
    font-size: 2rem;
    font-weight: 500;
    color: #b9b9b9;
  }

  .survey__gameName {
    margin-top: 1rem;
    font-size: 3rem;
    font-weight: 700;
  }

  .survey__gameBanner {
    margin-top: 3.5rem;

    img {
      display: block;
    }
  }

  .survey__finish {
    padding-top: 7.5rem;
    margin: 0 auto;
    width: 49.7rem;
    text-align: center;
    color: #f8f8f8;

    .survey__finishIcon {
      margin: 0 auto;
      width: 14.5rem;
    }

    .survey__finishTit {
      margin-top: 5.3rem;
      font-size: 3.36rem;
      font-weight: 700;
    }

    .survey__finishTxt {
      margin-top: 4.4rem;
      font-size: 2.8rem;
    }

    .survey__finishBanner {
      margin-top: 5.3rem;

      img {
        display: block;
      }
    }

    .survey__finishBtn {
      margin-top: 5rem;
    }
  }

  .surveyList {
    margin-top: 3rem;

    .surveyList__item {
      padding: 3.8rem 5rem;
      border-radius: 1rem;
      background-color: #f8f8f8;

      + .surveyList__item {
        margin-top: 2.6rem;
      }
    }

    .surveyList__tit {
      @include display-flex($display: flex, $justifyContent: space-between);
      color: #262626;

      .surveyList__caution {
        font-size: 1.6rem;
        font-weight: 500;
        text-align: right;
        color: #8e8e8e;
      }
    }

    .surveyList__answer {
      margin-top: 4.3rem;

      li {
        color: #555;

        + li {
          margin-top: 2.6rem;
        }
      }
    }
  }

  .surveyChk {
    @include display-flex($display: flex, $alignItems: center, $gap: 1.5rem);
    font-size: 1.6rem;
    font-weight: 500;

    .surveyChk__input {
      &:checked {
        + .surveyChk__style {
          background-color: #2282e9;
          transition: all ease 0.3s;
        }
      }
    }

    .surveyChk__style {
      position: relative;
      flex-shrink: 0;
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 50%;
      background-color: #d1d1d1;
      // transition: all ease 0.3s;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }

  .surveyList__text {
    @include display-flex($display: flex, $alignItems: flex-end, $gap: 1.2rem);
    margin-top: 2.5rem;

    .suveyList__input,
    .surveyList__textarea {
      border-radius: 1rem;
      border: none;
      outline: none;
      font-size: 1.8rem;
      background-color: #f1f1f1;
    }

    .suveyList__input {
      padding: 0 1.5rem;
      min-width: 39.9rem;
      height: 5.9rem;
    }

    .surveyList__textarea {
      width: 100%;
      min-height: 22.8rem;
      padding: 1.5rem;
      resize: none;
    }

    .wordCount {
      font-size: 1.6rem;
      color: #cacaca;
    }

    &.is-textarea {
      position: relative;

      .wordCount {
        position: absolute;
        bottom: 1.3rem;
        right: 2.5rem;
      }
    }
  }

  .survey__btn {
    @include display-flex(
      $display: flex,
      $flexDirection: row-reverse,
      $justifyContent: space-between
    );
    margin-top: 3rem;
  }

  .btn {
    padding: 1.7rem 0;
    min-width: 11.6rem;
    width: auto;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .contents__box {
    padding: 2.6rem 1.8rem;

    .contents__boxTit {
      padding: 0 1.8rem;
    }

    .contents__boxTxt {
      margin-top: 1.4rem;
    }
  }

  .guide {
    .guide__btn {
      &.is-active {
        + .guide__con {
          display: block;
        }
      }
    }
    .guide__con {
      top: 5.8rem;
      padding: 2.3rem 2.7rem 1.8rem;
      width: 64.3rem;

      .guide__list {
        gap: 0.3rem;
        --cols: 4;
        --gap: 0.3rem;

        .guide__item {
          padding: 2.8rem 0 1.5rem;
          height: 15.2rem;

          .guide__tit {
            padding: 0.3rem 1.2rem 0.3rem 3.8rem;

            .tit {
              padding: 0;
              font-size: 1rem;
              line-height: 1.4rem;
            }

            .num {
              padding: 0.2rem 0.7rem;
              font-size: 1.3rem;
              line-height: 1.6rem;
            }
          }
          .guide__img {
            height: 6.4rem;

            &.is-img-1,
            &.is-img-4 {
              width: 12.6rem;
            }

            &.is-img-2 {
              width: 7.2rem;
            }

            &.is-img-3 {
              width: 10rem;
            }
          }

          .guide__text {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .gameState {
    padding: 0 1.8rem;

    .tag {
      padding: 0.4rem 2.5rem 0.4rem 0.4rem;
      font-size: 1.8rem;
    }

    .gameState__progress {
      .gameState__progressVal {
        font-size: 2rem;
      }

      .progress {
        margin-top: 0.8rem;
      }
    }
  }

  .rewardList {
    overflow: auto;
    padding: 0 1.8rem;

    .rewardList__item {
      flex-shrink: 0;
      width: 11.8rem;
    }

    + .contents__box {
      margin-top: 4.6rem;
    }
  }

  .gameList {
    padding: 0 0.7rem;
    margin-top: 6.9rem;

    .gameList__top {
      padding: 0 1.3rem;

      .searchForm {
        display: none;
      }
    }

    .gameList__list {
      @include display-flex($display: flex, $flexWrap: wrap, $gap: 3rem 1.8rem);
      --cols: 2;
      --gap: 1.8rem;
      margin-top: 6.5rem;

      .gameList__listItem {
        flex-basis: calc(
          100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1)
        );
      }

      .gameList__listImg {
        position: relative;

        .tag {
          position: absolute;
          top: 2rem;
          left: 2rem;
          padding: 0.5rem 1rem;
          gap: 0.8rem;
        }
      }

      .gameList__listCon {
        padding: 0.8rem 2rem 2rem;
        background-color: #262626;

        h2 {
          font-size: 2rem;
        }

        p {
          font-size: 1.2rem;
          color: #a7a7a7;
        }

        .btnWrap {
          margin-top: 1.5rem;
        }

        .btn {
          padding: 1.4rem 0 1.3rem;
          font-size: 1.4rem;

          + .btn {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .survey {
    padding-top: 0;

    .btn {
      min-width: 19.3rem;
      font-size: 2.4rem;
    }

    .survey__btn {
      margin-top: 2.6rem;
    }
  }
}

// 뷰포트의 가로너비가 743px 이하인 경우 적용 : 모바일 환경
@media screen and (max-width: 743px) {
  .header {
    .header__breadcrumbs {
      font-size: 1.2rem;
    }
    .header__user {
      display: none;
    }
  }

  .contents__box {
    padding: 2.5rem 1.8rem;

    .contents__boxTit {
      padding: 0 0.5rem;
      font-size: 1.6rem;
    }

    .contents__boxTxt {
      margin-top: 3rem;
      font-size: 1.2rem;
    }
  }

  .guide {
    .guide__btn {
      &.is-active {
        + .guide__con {
          display: block;
        }
      }
    }
    .guide__con {
      top: 3.8rem;
      padding: 3.6rem 1.4rem 4.3rem;
      width: calc(100% - 3.6rem);

      .guide__list {
        gap: 2.6rem 0.7rem;
        flex-wrap: wrap;
        --cols: 2;
        --gap: 0.7rem;

        .guide__item {
          padding: 2.8rem 0 1.5rem;
          height: 15.2rem;

          .guide__tit {
            padding: 0.3rem 1.2rem 0.3rem 3.8rem;

            .tit {
              padding: 0;
              font-size: 1rem;
              line-height: 1.4rem;
            }

            .num {
              padding: 0.2rem 0.7rem;
              font-size: 1.3rem;
              line-height: 1.6rem;
            }
          }
          .guide__img {
            height: 6.4rem;

            &.is-img-1,
            &.is-img-4 {
              width: 12.6rem;
            }

            &.is-img-2 {
              width: 7.2rem;
            }

            &.is-img-3 {
              width: 10rem;
            }
          }

          .guide__text {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .gameState {
    gap: 1.5rem;

    .tag {
      padding: 0.2rem 0.9rem 0.2rem 0.2rem;
      font-size: 1.2rem;
      gap: 0.65rem !important;

      .icon {
        &.is-arrow-right {
          width: 0.5rem;
          height: 0.8rem;
          background-size: 0.5rem 0.8rem;
        }
      }
    }

    .gameState__icon {
      width: 2.275rem;
      height: 2.275rem;
      background-size: 1.625rem;
    }

    .gameState__progress {
      .icon {
        &.is-star {
          width: 1.5rem;
          height: 1.5rem;
          background-size: 1.5rem;
        }
      }
      .gameState__progressVal {
        font-size: 1.2rem;
      }

      .progress {
        margin-top: 0.3rem;
      }
    }
  }

  .rewardList {
    overflow: auto;
    padding: 0 0.2rem;

    .rewardList__item {
      padding: 0.79rem 0.639rem 0.719rem 0.559rem;
      flex-shrink: 0;
      width: 7.4rem;

      span {
        font-size: 0.958rem;
      }
    }

    + .contents__box {
      margin-top: 4.6rem;
    }

    .rewardList__btn {
      margin-top: 0.559rem;
      button {
        height: 1.917rem;
        font-size: 0.958rem;
      }
    }
  }

  .gameList {
    .gameList__top {
      display: block;

      .gameList__search {
        justify-content: space-between;
      }

      .searchForm {
        .searchForm__input {
          width: 100%;
          padding: 0.95rem 6.4rem 0.95rem 3.3rem;
          font-size: 1.6rem;
        }

        .searchForm__btn {
          width: 2.7rem;
          height: 2.7rem;
          background-size: 2.7rem;
        }
      }
    }

    .gameList__tab {
      margin-top: 3rem;
      padding: 0 2.7rem;
      gap: 2.2rem;

      .tab {
        + .tab {
          padding-left: 0;
          margin-left: 0;

          &::before {
            display: none;
          }
        }

        .tab__link {
          position: relative;
          padding: 0.4rem 3rem 0.8rem;
          font-size: 1.6rem;

          &.is-active {
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 0.4rem;
              background-color: #ff852b;
              border-radius: 999rem;
            }
          }
        }
      }
    }

    .gameList__list {
      margin-top: 2.6rem;
      flex-direction: column;
      gap: 1.4rem;

      .gameList__listItem {
        @include display-flex(
          $display: flex,
          $alignItems: center,
          $gap: 1.3rem
        );
        padding-left: 1rem;
        flex-basis: 100%;
        border: 0.1rem solid #262626;
        border-radius: 1.3rem;
      }

      .gameList__listImg {
        position: relative;
        flex-shrink: 0;
        width: 7.4rem;
        height: 7.4rem;
        border-radius: 1rem;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: 100%;
          transform: translate(-50%, -50%);
        }
      }

      .tag {
        top: auto;
        left: 0;
        bottom: 0;
        height: 2rem;
        font-size: 1rem;
      }

      .gameList__listCon {
        @include display-flex(
          $display: flex,
          $alignItems: center,
          $gap: 1.2rem,
          $justifyContent: space-between
        );

        flex-grow: 1;
        padding: 0;
        background-color: transparent;

        h2 {
          font-size: 1.6rem;
        }

        p {
          font-size: 1rem;
          color: #a7a7a7;
        }

        .info {
          position: relative;
          padding-bottom: 2.9rem;
        }

        .btnWrap {
          flex-shrink: 0;
          margin-top: 0;
          padding: 0 2.2rem;
          border-left: 0.1rem solid #262626;
        }

        .btn {
          border: none;
          font-size: 1.6rem;
          background-color: transparent !important;

          + .btn {
            position: relative;
            margin-top: 0;

            &::before {
              content: "";
              position: absolute;
              left: -2.2rem;
              top: 0;
              width: calc(100% + 4.4rem);
              height: 0.1rem;
              background-color: #262626;
            }
          }

          &.is-outline-gray {
            color: #c0c0c0;
          }

          &.is-fill-orange {
            color: #ff852b;
          }
        }
      }
    }
  }

  .survey {
    padding-top: 0 !important;
    width: 100%;

    .survey__gameBanner {
      margin-top: 3.8rem;
    }

    .surveyList {
      margin-top: 5rem;

      .surveyList__item {
        padding: 3.8rem 3.3rem;
      }

      .surveyList__tit {
        flex-direction: column;

        .surveyList__caution {
          font-size: 1.2rem;
        }
      }
    }

    .surveyList__text {
      margin-top: 0.5rem;

      .suveyList__input {
        min-width: 70%;
      }
    }

    .survey__finish {
      padding-top: 9.6rem;
      width: 100%;

      .survey__finishIcon {
        width: 8.4rem;
      }

      .survey__finishTit,
      .survey__finishTxt {
        margin-top: 3.2rem;
      }

      .survey__finishTit {
        font-size: 2.6rem;
      }

      .survey__finishTxt {
        font-size: 2rem;
      }

      .survey__finishBtn {
        margin-top: 6rem;
      }
    }
  }
}
