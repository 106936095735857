$primary: #2282E9;
// $gray-1: #D1D1D1; //지정 필요
// $gray-2: #8E8E8E; //지정 필요
$gray-3: #D1D1D1;
$gray-4: #C0C0C0;
// $gray-5: #D1D1D1; //지정 필요
$gray-6: #8E8E8E;
$gray-7: #555;
$gray-border: #E3E3E3;
$grays: (
  // "1": $gray-1,
  // "2": $gray-2,
  "3": $gray-3,
  "4": $gray-4,
  // "5": $gray-5,
  "6": $gray-6,
  "7": $gray-7,
  "border": $gray-border
);

.fc-primary {
  color: $primary;
}

@each $color, $value in $grays {
  .fc-gray-#{$color} {
    color: $value !important;
  }
}


// Display Flex
@mixin display-flex ($display: null, $justifyContent: null, $alignItems: null, $flexDirection: null, $flexWrap: null, $gap: null) {
  display: $display;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $flexDirection;
  flex-wrap: $flexWrap;
  gap: $gap;
}

// X, Y Center
@mixin flex-center() {
  @include display-flex($display: flex, $justifyContent: center, $alignItems: center);
}
@mixin inline-flex-center() {
  @include display-flex($display: inline-flex, $justifyContent: center, $alignItems: center);
}

.d-flex {
  @include display-flex($display: flex !important);
}

.flex-direction-column {
  @include display-flex($flexDirection: column !important);
}

.justify-content-between {
  @include display-flex($justifyContent: space-between !important);
}


.justify-content-end {
  @include display-flex($justifyContent: flex-end !important);
}

.justify-content-center {
  @include display-flex($justifyContent: center !important);
}

.align-items-center {
  @include display-flex($alignItems: center !important);
}

.align-items-end {
  @include display-flex($alignItems: flex-end !important);
}

.flex-shrink-0 {
  flex-shrink: 0;
}

@for $i from 0 through 60 {
  // gap
  .gap-#{$i} {
    gap: $i * 0.1 + rem !important;
  }

  // border radius
  .border-radius-#{$i} {
    border-radius: $i * 0.1 + rem !important;
  }

  // font size
  .fs-#{$i} {
    font-size: $i * 0.1 + rem !important;
  }

  // button height
  .height-#{$i} {
    min-height: $i * 0.1 + rem !important;
  }
}


@for $i from 0 through 9 {
  // font weight
  .fw-#{$i} {
    font-weight: $i * 100 !important;
  }
}


@for $i from 0 through 200 {
  // margin
  .m-#{$i} {
    margin: $i * 0.1 + rem !important;
  }
  .mt-#{$i} {
    margin-top: $i * 0.1 + rem !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.1 + rem !important;
  }
  .ml-#{$i} {
    margin-left: $i * 0.1 + rem !important;
  }
  .mr-#{$i} {
    margin-right: $i * 0.1 + rem !important;
  }

  // padding
  .p-#{$i} {
    padding: $i * 0.1 + rem !important;
  }
  .pt-#{$i} {
    padding-top: $i * 0.1 + rem !important;
  }
  .pb-#{$i} {
    padding-bottom: $i * 0.1 + rem !important;
  }
  .pl-#{$i} {
    padding-left: $i * 0.1 + rem !important;
  }
  .pr-#{$i} {
    padding-right: $i * 0.1 + rem !important;
  }

  .ps-#{$i} {
    padding-left: $i * 0.1 + rem !important;
    padding-right: $i * 0.1 + rem !important;
  }
}