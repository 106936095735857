.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;

    &.is-star {
        width: 3rem;
        height: 3rem;
        background-image: url('../images/icon-star.svg');
    }

    &.is-arrow-right {
        width: 1.4rem;
        height: 1.9rem;
        background-image: url('../images/icon-arrow-right.svg');
    }
    
    &.is-user {
        width: 1rem;
        height: 1.1rem;
        background-image: url('../images/icon-user.svg');
    }
}