html {
  ::-webkit-scrollbar {
    height: 0.5rem;
    width: 1rem;
    border-radius: 999rem;
    background-color: transparent;
    overflow: hidden;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 990rem;
    background-color: #9d9d9d;
  }

  ::-webkit-scrollbar-track {
    border-radius: 999rem;
    background-color: #3f3f3f;
  }
}
img {
  width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;
}

.tag {
  @include display-flex($display: inline-flex, $alignItems: center);
  border-radius: 999rem;

  &.is-sm {
    font-size: 1.2rem;
  }

  &.is-fill-orange {
    background-color: #ff852b;
  }
}

.btn {
  @include display-flex(
    $display: flex,
    $alignItems: center,
    $justifyContent: center
  );
  width: 100%;
  border: 0;
  font-weight: 600;

  &.is-round {
    border-radius: 999rem;
  }

  &.is-fill-orange {
    background-color: #ff852b;
  }

  &.is-fill-blue {
    background-color: #2282e9;
    color: #fff;
  }

  &.is-fill-gray {
    background-color: #a7a7a7;
    color: #fff;
  }

  &.is-outline-gray {
    border: 0.2rem solid #303030;
  }
}

.tabs {
  @include display-flex($display: flex, $alignItems: center);
}

.tab__contents {
  display: none;

  &.is-active {
    display: block;
  }
}

.progress {
  progress {
    display: block;
    inline-size: 100%;
    height: auto;
    -webkit-appearance: none;

    &::-webkit-progress-bar,
    &::-webkit-progress-value {
      height: 1.7rem;
      border-radius: 1rem;
    }

    &::-webkit-progress-bar {
      background-color: #353535;
    }

    &::-webkit-progress-value {
      background-color: #ff852b;
    }
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .progress {
    progress {
      &::-webkit-progress-bar,
      &::-webkit-progress-value {
        height: 1rem;
      }
    }
  }
}

@media screen and (max-width: 743px) {
  .progress {
    progress {
      &::-webkit-progress-bar,
      &::-webkit-progress-value {
        height: 0.5rem;
      }
    }
  }
}
